<template>
  <div class="login">
    <div class="fixed top-0 right-0 p-4">
      <LocaleSwitcher v-on:change="$emit('localeChange', $event)" />
    </div>
    <LoadingSpinner :isLoading="isLoading" />
    <div class="min-h-screen bg-primary-300 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white lg:w-1/3 p-8 md:p-12 rounded-lg shadow-xl">
        <div>
          <router-link to="/">
            <img class="logo w-40 mx-auto" :src="require('@/assets/logos/logo_horizontal.png')" />
          </router-link>
        </div>
        <div class="mt-8">
          <main v-if="!passwordResetSuccess">
            <section class="text-center">
              <h3 class="font-bold text-2xl">{{ $t("forgot_password_page.title") }}</h3>
              <p class="text-gray-600 pt-2">{{ $t("forgot_password_page.message") }}</p>
            </section>
            <section class="mt-8">
              <ValidationObserver ref="formForgotPassword">
                <div class="flex flex-col">
                  <BaseInput v-model="username" type="text" :field_name="$t('forgot_password_page.username')" rules="required" />
                  <button class="btn-blue px-2 py-3 text-sm mt-5" @click.prevent="resetPassword()">
                    {{ $t("forgot_password_page.reset_password") }}
                    <BaseIcon icon="undo" class="ml-2" />
                  </button>
                </div>
              </ValidationObserver>
            </section>
          </main>

          <main v-if="passwordResetSuccess">
            <section class="text-center">
              <h3 class="font-bold text-2xl">{{ $t("forgot_password_page.check_email") }}</h3>
              <p class="text-gray-600 pt-3">{{ $t("forgot_password_page.email_sent_message") }}</p>
            </section>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/locale_switcher";
export default {
  name: "ForgotPassword",
  title() {
    return `Glömt ditt lösenord? | SecurCloud`;
  },
  components: {
    LocaleSwitcher,
  },
  data() {
    return {
      isLoading: false,
      username: "",
      passwordResetSuccess: false,
    };
  },

  methods: {
    async resetPassword() {
      this.$refs.formForgotPassword.validate().then(async (success) => {
        if (!success) {
          return;
        }

        try {
          this.isLoading = true;
          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/forgot_password`, { username: this.username.toLowerCase() });
          this.isLoading = false;

          if (response.data.error) this.handleError(null, response.data.message);

          if (response.data.success) this.passwordResetSuccess = true;
        } catch (error) {
          this.isLoading = false;
          this.handleError(error);
        }
      });
    },
  },
};
</script>
